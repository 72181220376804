@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --blue-100: #2C95E8;
    --blue-5: #F1F8FD;
    --blue-20: #E4F1FC;
    --blue-30: #CDE6F9;
    --blue-40: #C3E1F8;
    --blue-60: #95C9F3;
    --blue-120: #1678C5;
    --blue-170: #08528C;

    --red-100: #E97E63;
    --red-30: #FDD1C9;
    --red-170-40: rgba(159, 58, 30, 0.4);
    --red-170: #9F3A1E;

    --yellow-100: #F3AB1B;
    --yellow-30: #FBE4B6;
    --yellow-170-40: rgba(153, 102, 0, 0.4);
    --yellow-170: #996600;

    --green-100: #2FB163;
    --green-30: #C4EDCE;
    --green-170-40: rgba(40, 113, 69, 0.4);
    --green-170: #287145;

    --purple-100: #7147C2;
    --purple-30: #E4D6FF;
    --purple-60: #AC94DB;
    --purple-120: #5935A0;

    --orange-100: #F36E1B;
    --orange-30: #FFE0CC;
    --orange-60: #F9B58B;
    --orange-120: #DA5B0B;

    --black-100: #212121;
    --white-100: #FFFFFF;
    --white-100-20: rgba(255, 255, 255, 0.2);
    --white-100-50: rgba(255, 255, 255, 0.5);
    --black-5: #F5F5F5;
    --black-15: #DEDEDE;
    --black-30: #BFBFBF;
    --black-50: #A1A1A1;
    --black-60: #8A8A8A;
    --black-70: #666666;

    --menu-size: 220px;
}

::-webkit-scrollbar {
    display: none;
}

body * {
    font-family: 'Manrope', sans-serif;
}

.input {
    height: 40px;
    border-radius: 12px;
    border: 1px solid var(--black-15);
    padding: 0 20px 0 40px;
    position: relative;
}

input:focus-visible, textarea:focus-visible {
    outline: transparent;
}

.main-router-content, .portal-content {
    min-height: calc(100% - 80px);
    height: 100%;
    overflow-y: scroll;
}

.input-container:before, .input-container-search:before, .input-address-container:before {
    content: '';
    position: absolute;
    left: 12px;
    top: 11px;
    width: 18px;
    height: 22px;
    background: url('../icons/search.svg') no-repeat;
    background-size: contain;
    z-index: 1;
}

.list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
}

.without-top {
    padding-top: 0;
}

.header-buttons {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-buttons button {
    margin-left: 10px;
}

.header-input {
    width: 100%;
}

.header-input input {
    font-size: 2em;
    font-weight: 700;
    border: 0;
    width: 100%;
    padding: 0;
    height: 40px;
}

.header-input input::placeholder {
    color: var(--black-15);
}

.header-title h1 {
    font-size: 2em;
    font-weight: 800;
    padding-left: 50px;
    position: relative;
    margin-bottom: 0;
}

.header-title h1:before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 30px;
    height: 24px;
    background: url('../icons/folder-blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.header-title.settings h1:before {
    left: 0;
    top: 5px;
    width: 30px;
    height: 30px;
    background: url('../icons/settings-blue.svg') no-repeat;
    background-size: contain;
}

.header-title.users h1:before {
    top: 5px;
    width: 30px;
    height: 30px;
    background: url('../icons/users-blue.svg') no-repeat;
    background-size: contain;
}

.header-title.subscriptions h1:before {
    top: 5px;
    left: 3px;
    width: 30px;
    height: 30px;
    background: url('../icons/ticket-blue.svg') no-repeat;
    background-size: contain;
}

.header-title.stadium h1:before {
    top: 7px;
    left: 2px;
    width: 26px;
    height: 26px;
    background: url('../icons/stadium--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.scanner h1:before {
    top: 5px;
    width: 30px;
    height: 30px;
    background: url('../icons/scanner--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.pools h1:before {
    top: 5px;
    width: 30px;
    height: 30px;
    background: url('../icons/home-location-alt-blue.svg') no-repeat;
    background-size: contain;
}

.header-title.trainings h1:before {
    top: 5px;
    width: 30px;
    height: 30px;
    background: url('../icons/swimmer-blue.svg') no-repeat;
    background-size: contain;
}

.header-title h2 {
    font-size: 1.6em;
    font-weight: 800;
    padding-left: 50px;
    position: relative;
    margin-bottom: 0;
}

.header-title h2:before {
    content: '';
    position: absolute;
    left: 2px;
    top: 5px;
    width: 24px;
    height: 24px;
    background: url('../icons/folder-blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.header-title.users h2:before {
    left: 3px;
    top: 3px;
    width: 24px;
    height: 24px;
    background: url('../icons/account.svg') no-repeat;
    background-size: contain;
}

.main-button, .small-button {
    background: var(--white-100);
    border: 1px solid var(--black-15);
    height: 40px;
    padding: 0 20px 0 50px;
    font-weight: 500;
    border-radius: 12px;
    position: relative;
    margin-bottom: 0;
    transition: all .3s;
    cursor: pointer;
}

.squared-button {
    background: var(--white-100);
    border: 1px solid var(--black-15);
    height: 40px;
    width: 40px;
    font-weight: 500;
    border-radius: 12px;
    position: relative;
    transition: all .3s;
}

.squared-button:hover {
    background: var(--black-5);
}

.input-container .squared-button {
    margin-left: 10px;
}

.squared-button.blue-button {
    background: var(--blue-100);
    border-color: var(--white-100);
}

.squared-button.blue-button:hover {
    background: var(--blue-120);
}

.squared-button.blue-button:before {
    width: 17px;
    top: 11px;
    left: 11px;
    background: url('../icons/plus-white.svg') no-repeat;
    background-size: contain;
}

.squared-button:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 17px;
    width: 18px;
    height: 22px;
    background: url('../icons/options--gray.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.squared-button.delete {
    border: 1px solid var(--red-30);
}

.squared-button.delete:before {
    background: url('../icons/delete--red.svg') no-repeat;
    background-size: contain;
    top: 10px;
    left: 10px;
}

.squared-button.filter:before {
    top: 11px;
    left: 10px;
    background: url('../icons/filter.svg') no-repeat;
    background-size: contain;
}

.squared-button.download:before {
    top: 10px;
    left: 10px;
    background: url('../icons/download--black.svg') no-repeat;
    background-size: contain;
}

.main-button.warning-button {
    border: 1px solid var(--red-30);
    color: var(--red-100);
}

.main-button.warning-button:hover {
    border: 1px solid var(--red-100);
    background: var(--red-100);
    color: var(--white-100);
}

.main-button.without-icon {
    padding: 0 20px;
}

.main-button.without-icon:before {
    display: none;
}

.input-group {
    padding: 30px;
}

.input-group.without-top {
    padding: 0 30px 30px 30px;
}

.input-label {
    width: 200px;
    padding-left: 35px;
    position: relative;
}

.input-container, .input-container-search {
    width: calc(100% - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.input-container input, .input-container-search input {
    width: 100%;
}

.input-container.without-icon:before {
    display: none;
}

.input-label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background: url('../icons/customers-blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.input-label.user:before {
    background: url('../icons/user-blue.svg') no-repeat;
    background-size: contain;
    left: 2px;
}

.input-label.group:before {
    background: url('../icons/users-blue.svg') no-repeat;
    background-size: contain;
    height: 20px;
    top: 2px;
}

.input-label.pool:before {
    background: url('../icons/home-location-alt-blue.svg') no-repeat;
    background-size: contain;
    height: 20px;
    top: 2px;
}

.input-label.phone:before {
    background: url('../icons/phone--blue.svg') no-repeat;
    background-size: contain;
    top: 2px;
}

.input-label.hidden-label:before {
    background: transparent;
}

.input-label.location:before {
    background: url('../icons/location--blue.svg') no-repeat;
    background-size: contain;
    top: 2px;
    width: 21px;
    height: 21px;
}

.input-label.reminder:before {
    background: url('../icons/reminder--blue.svg') no-repeat;
    background-size: contain;
    height: 21px;
    top: 1px;
}

.input-label.type:before {
    background: url('../icons/type--blue.svg') no-repeat;
    background-size: contain;
    height: 18px;
    top: 3px;
}

.input-label.email:before {
    background: url('../icons/email--blue.svg') no-repeat;
    background-size: contain;
    top: 2px;
}

.input-label.role:before {
    background: url('../icons/status--blue.svg') no-repeat;
    background-size: contain;
    height: 19px;
    top: 3px;
}

.input-label.calendar:before {
    background: url('../icons/calendar--blue.svg') no-repeat;
    background-size: contain;
    height: 20px;
    top: 2px;
}

.input-label.pen:before {
    background: url('../icons/pen-blue.svg') no-repeat;
    background-size: contain;
    height: 20px;
    top: 1px;
}

.input-label.folder:before {
    background: url('../icons/folder-blue.svg') no-repeat;
    background-size: contain;
    height: 16px;
    top: 4px;
}

.input-label {
    font-weight: 500;
}

.input-row-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.input-row-container.align-columns {
    flex-direction: column;
    align-items: flex-start;
}

.input-row-container.align-columns .input-container {
    width: 100%;
}

.input-row-container.align-columns .input-label {
    margin-bottom: 15px;
}

.input-textarea-container {
    width: 100%;
    margin-bottom: 40px;
}

.input-textarea-container:last-child {
    margin-bottom: 0;
}

.input-textarea textarea, .textarea-view {
    width: 100%;
    border-radius: 12px;
    border: 1px solid var(--black-15);
    padding: 10px 15px;
    position: relative;
    margin-top: 20px;
    resize: none;
}

.textarea-view {
    min-height: 160px;
    white-space: pre-line;
}

.main-button:hover, .small-button:hover {
    border: 1px solid var(--blue-60);
    background: var(--blue-20);
    color: var(--blue-100);
}

.main-button.blue-button {
    background: var(--blue-100);
    border: 0;
    color: var(--white-100);
    font-weight: 600;
    white-space: nowrap;
}

.main-button.blue-button:disabled, .main-button.blue-button:disabled:hover {
    background: var(--blue-20);
}

.main-button.blue-button:hover {
    background: var(--blue-120);
}

.main-button.blue-button:before {
    background: url('../icons/plus-white.svg') no-repeat;
    background-size: contain;
    width: 18px;
    left: 17px;
    transition: all .3s;
}

.main-button.blue-button.launch:before {
    background: url('../icons/launch--white.svg') no-repeat;
    background-size: contain;
    width: 20px;
}

.main-button.blue-button.launch:hover:before {
    background: url('../icons/launch--white.svg') no-repeat;
    background-size: contain;
}

.main-button.blue-button.edit:before {
    background: url('../icons/edit--white.svg') no-repeat;
    background-size: contain;
    width: 15px;
    left: 19px;
    top: 12px;
}

.main-button.blue-button.edit:hover:before {
    background: url('../icons/edit--white.svg') no-repeat;
    background-size: contain;
}

.main-button.blue-button.validate:before {
    background: url('../icons/check--white.svg') no-repeat;
    background-size: contain;
    width: 17px;
    left: 18px;
    top: 15px;
}

.main-button.blue-button.validate:hover:before {
    background: url('../icons/check--white.svg') no-repeat;
    background-size: contain;
}

.main-button.blue-button:hover:before {
    background: url('../icons/plus-white.svg') no-repeat;
    background-size: contain;
}

.main-button:hover:before, .small-button:hover:before {
    background: url('../icons/filter-blue.svg') no-repeat;
    background-size: contain;
}

.main-button.download:hover:before {
    background: url('../icons/download--blue.svg') no-repeat;
    background-size: contain;
}

.main-button:before, .small-button:before {
    content: '';
    position: absolute;
    left: 16px;
    top: 11px;
    width: 17px;
    height: 22px;
    background: url('../icons/filter.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.main-button.download:before {
    background: url('../icons/download--black.svg') no-repeat;
    background-size: contain;
}

.main-button.blue-button.download:before {
    background: url('../icons/download--white.svg') no-repeat;
    background-size: contain;
}

.main-button.validate:before {
    background: url('../icons/check--black.svg') no-repeat;
    background-size: contain;
    top: 14px;
}

.main-button.validate:hover:before {
    background: url('../icons/check--blue.svg') no-repeat;
    background-size: contain;
}

.main-button.delete:before {
    background: url('../icons/delete--red.svg') no-repeat;
    background-size: contain;
}

.main-button.delete:hover:before {
    background: url('../icons/delete--white.svg') no-repeat;
    background-size: contain;
}

.main-button.archive:before {
    background: url('../icons/archive--black.svg') no-repeat;
    background-size: contain;
    width: 21px;
}

.main-button.archive:hover:before {
    background: url('../icons/archive--blue.svg') no-repeat;
    background-size: contain;
}

.main-button.small-button.download:before {
    top: 9px;
    left: 11px;
}

.search-list {
    position: absolute;
    background: var(--white-100);
    width: 100%;
    top: 40px;
    border-radius: 0 0 12px 12px;
    border: 1px solid var(--black-15);
    border-top: 0;
    max-height: 306px;
    overflow: hidden;
}

.search-list.hidden {
    display: none;
}

.input-container-search input:focus {
    border-radius: 12px 12px 0 0;
}

.search-list span {
    font-size: 0.8em;
    padding: 10px;
    display: block;
    font-weight: 500;
    color: var(--black-70);
}

.custom-tag, .single-search-input .select__single-value, .multi-search-input .select__multi-value {
    font-weight: 600;
    color: var(--blue-120);
    background: var(--blue-20);
    padding: 2px 9px;
    width: fit-content;
    border-radius: 9px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.multi-search-input .select__multi-value {
    margin-right: 5px;
    padding: 2px 2px 2px 9px;
}

.multi-search-input .select__multi-value__remove {
    padding: 2px;
    border-radius: 10px;
    margin: 0 2px;
    cursor: pointer;
}

.multi-search-input .select__multi-value__label {
    color: var(--blue-120);
    padding: 0;
    font-size: 1em;
}

.custom-tag-container {
    width: 100%;
    padding: 8px 0 8px 10px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
}

.custom-tag-container:hover {
    background: var(--black-5);
}

.selected-tag {
    position: absolute;
    z-index: 10;
}

.hidden {
    display: none;
}

.single-search-input, .multi-search-input {
    width: 100%;
    border-radius: 12px;
}

.single-search-input .select__value-container, .multi-search-input .select__value-container {
    padding-left: 40px;
    height: 40px;
}

.single-search-input .select__control, .multi-search-input .select__control {
    border-radius: 12px;
    height: 40px;
}

.single-search-input .select__menu, .multi-search-input .select__menu, .weekday-select .select__menu {
    z-index:11;
    border-radius: 12px;
}

.single-search-input .select__menu-list::-webkit-scrollbar, .multi-search-input .select__menu-list::-webkit-scrollbar, .weekday-select .select__menu-list::-webkit-scrollbar {
    display: none;
}

.single-search-input .select__menu-list, .multi-search-input .select__menu-list, .weekday-select .select__menu-list {
    padding: 0;
    -ms-overflow-style: none;
}

.list-container {
    height: calc(100vh - 180px);
    overflow-y: scroll;
}

.list-container table {
    width: 100%;
    font-size: .9em;
}

.list-container tr {
    height: 45px;
}

.list-container thead {
    background: var(--blue-5);
    border-bottom: 1px solid var(--blue-20);
    color: var(--black-70);
}

.list-container tbody tr {
    border-bottom: 1px solid var(--black-5);
}

.loader-container {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-size: .9em;
    color: var(--black-70);
    font-weight: 500;
}

/* Customize the label (the container) */
.checkbox-container {
    display: block;
    position: relative;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 30px;
    height: 45px;
}

.checkbox-container.pastille-checkbox-div {
    padding-left: 45px;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 13px;
    left: 13px;
    height: 20px;
    width: 20px;
    border-radius: 7px;
    background: var(--black-5);
    border: 1px solid var(--black-15);
    transition: all .2s;
}

.checkbox-container:hover input ~ .checkmark {
    background-color: #efefef;
}

.checkbox-container input:checked ~ .checkmark {
    background-color: var(--blue-100);
    border: 1px solid var(--blue-100);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 4px;
    top: 4px;
    width: 10px;
    height: 10px;
    background: url('../icons/white-smooth-check.svg') no-repeat;
    background-size: cover;
    transition: all .2s;
}

.pagination-button {
    border: 1px solid var(--black-30);
    background: var(--white-100);
    border-radius: 10px;
    width: 32px;
    height: 32px;
    margin: 0 2px;
    font-weight: 600;
    color: var(--black-70);
    padding: 0;
    transition: all .2s;
}

.pagination-button.select {
    width: fit-content;
}

.pagination-button:hover {
    background: var(--blue-100);
    border: 1px solid var(--blue-100);
    color: var(--white-100);
}

.pagination-button:disabled {
    border: 1px solid var(--black-15);
    color: var(--black-15);
}

.pagination-button:disabled:hover {
    background: var(--white-100);
    border: 1px solid var(--black-15);
    color: var(--black-15);
}

.padding-right {
    padding-right: 10px;
}

.list-container th {
    position: relative;
}

.sorted, .sorted-desc {
    position: relative;
}

.sorted:before {
    content: '';
    position: absolute;
    left: 8px;
    top: 7px;
    width: 8px;
    height: 8px;
    background: url('../icons/sort-ascendant--gray.svg') no-repeat;
    background-size: contain;
}

.sorted-desc:before {
    content: '';
    position: absolute;
    left: 8px;
    top: 7px;
    width: 8px;
    height: 8px;
    background: url('../icons/sort-descendant--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon {
    padding-left: 24px;
    position: relative;
}

.list-container .column-icon:before {
    content: '';
    position: absolute;
    left: 0;
    top: 16px;
    width: 15px;
    height: 15px;
    background: url('../icons/supervisor--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.email:before {
    background: url('../icons/project--gray.svg') no-repeat;
    background-size: contain;
    top: 17px;
}

.list-container .column-icon.pen:before {
    background: url('../icons/name--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.id:before {
    background: url('../icons/id--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.customer:before {
    background: url('../icons/customer--gray.svg') no-repeat;
    background-size: contain;
    height: 17px;
    width: 17px;
}

.list-container .column-icon.calendar:before {
    background: url('../icons/calendar--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.order:before {
    background: url('../icons/order--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.role:before {
    background: url('../icons/status--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.type:before {
    background: url('../icons/type--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.provider:before {
    background: url('../icons/supplier--gray.svg') no-repeat;
    background-size: contain;
    height: 18px;
    width: 18px;
}

.list-container .column-icon.project:before {
    background: url('../icons/project--gray.svg') no-repeat;
    background-size: contain;
    top: 18px;
    width: 16px;
}

.custom-tag {
    background: var(--blue-30);
    padding: 0 10px;
    border-radius: 8px;
    color: var(--blue-120);
    font-weight: 600;
    font-size: 1em;
}

.custom-tag.active, .custom-tag.delivered, .custom-tag.true, .custom-tag.guest {
    background: var(--green-30);
    color: var(--green-170);
}

.custom-tag.progress {
    background: var(--yellow-30);
    color: var(--yellow-170);
}

.custom-tag.production {
    background: var(--orange-30);
    color: var(--orange-120);
}

.custom-tag.inactive, .custom-tag.difficulty, .custom-tag.false {
    background: var(--red-30);
    color: var(--red-170);
}

.custom-tag.archived, .custom-tag.draft {
    background: var(--black-5);
    color: var(--black-70);
}

.custom-tag.fabrics, .custom-tag.customer {
    background: var(--purple-30);
    color: var(--purple-120);
}

.list-container a {
    text-decoration: none;
    color: var(--black-100);
    font-weight: 500;
}

.view-container {
    padding: 30px;
}

.view-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 30px;
}

.view-title h1, .custom-modal-header h1 {
    font-size: 2em;
    font-weight: 800;
    position: relative;
    margin-bottom: 0;
}

.portal-title h1, h1.order-portal-welcome {
    font-size: 1.5em;
    font-weight: 800;
    position: relative;
    margin-bottom: 0;
}

.view-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.margin-left {
    margin-left: 10px;
}

.margin-right {
    margin-right: 10px;
}

.margin-top {
    margin-top: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.view-title .view-id, .portal-title span {
    color: var(--black-50);
    font-weight: 600;
    display: block;
    padding-top: 5px;
}

.classic-input, .search-input, .contact-container {
    height: 40px;
    border-radius: 12px;
    border: 1px solid var(--black-15);
    padding: 0 15px;
}

.search-input {
    padding-left: 40px;
}

.input-required, .input-required:focus-visible {
    border: 2px solid var(--red-100);
    outline: transparent;
}

.relative {
    position: relative;
}

.input-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-date-container .react-datepicker {
    border-radius: 12px;
}

.input-date-container .react-datepicker__header {
    border-top-left-radius: 12px;
}

.input-date-container .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 12px;
}

.input-date-container .react-datepicker__year-read-view--down-arrow, .input-date-container .react-datepicker__month-read-view--down-arrow, .input-date-container .react-datepicker__month-year-read-view--down-arrow, .input-date-container .react-datepicker__navigation-icon::before {
    top: 12px;
}

input.name-input-required::placeholder {
    color: var(--red-30);
}

.input-date-container .date-view, .input-classic-container {
    height: 40px;
    border: 1px solid var(--black-15);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 12px;
    font-weight: 500;
}

.input-classic-container {
    width: 100%;
    height: 50px;
    border-radius: 16px;
    padding: 0 20px;
    justify-content: left;
}

th.checkbox-fixed-size {
    width: 60px;
}

.main-button.small-button {
    height: 35px;
    padding: 0 12px 0 35px;
}

label.main-button.small-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-button.small-button.blue-button:before {
    left: 10px;
    top: 9px;
}

.delete-icon-button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: 0;
    background: var(--white-100);
    transition: all .3s;
}

.delete-icon-button:hover {
    background: var(--red-30);
}

.delete-icon-button:before {
    content:'';
    position: absolute;
    left: 7px;
    top: 6px;
    width: 17px;
    height: 17px;
    background: url('../icons/delete-full--red.svg') no-repeat;
    background-size: contain;
}

.contact-container .delete-icon-button {
    top: 10px;
}

.update-icon-button {
    position: absolute;
    top: 11px;
    right: 40px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: 0;
    background: var(--white-100);
    transition: all .3s;
}

.update-icon-button:before {
    content:'';
    position: absolute;
    left: 7px;
    top: 6px;
    width: 17px;
    height: 17px;
    background: url('../icons/pen-blue.svg') no-repeat;
    background-size: contain;
}

.custom-modal {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0,0,0,.2);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-modal.hidden {
    display: none;
}

.custom-modal-container {
    width: 600px;
    min-height: 50px;
    background: var(--white-100);
    border-radius: 12px;
    padding: 20px;
    transition: all .3s;
}

.custom-modal-header {
    border-bottom: 1px solid var(--black-5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.custom-modal-header .modal-close {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    transform: rotate(45deg);
    border: 0;
    background: transparent;
    transition: all .3s;
}

.custom-modal-header .modal-close:hover {
    background: var(--black-5);
}

.submit-right-container {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.contact-container {
    width: 100%;
    height: 50px;
    display: flex;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    position: relative;
    padding: 0 75px 0 20px;
    font-weight: 500;
}

.update-icon-button:hover {
    background: var(--blue-5);
}

.contact-container.full-width {
    padding: 0 20px;
}

.contact-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-info span {
    width: 100%;
}

.input-address-container {
    font-weight: 500;
    width: 100%;
    border: 1px solid var(--black-15);
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    border-radius: 16px;
    position: relative;
}

.input-address-container:before {
    top: 15px;
}

.no-value {
    font-weight: 500;
    width: 100%;
    background: var(--red-30);
    border: 1px solid var(--red-100);
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-radius: 16px;
    position: relative;
}

.provider-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.provider-block {
    width: 210px;
    height: 120px;
    margin-right: 18px;
    border: 1px solid var(--black-15);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: all .3s;
}

.provider-block.fabrics:hover, .provider-block.fabrics.checked {
    background: var(--purple-30);
    border: 1px solid var(--purple-60);
}

.provider-block.fabrics:hover .provider-check, .provider-block.fabrics.checked .provider-check {
    border: 1px solid var(--purple-60);
}

.provider-block .provider-check:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: transparent;
    border-radius: 10px;
    transition: all .3s;
}

.provider-block.fabrics.checked .provider-check:before {
    background: var(--purple-100);
}

.provider-block.production.checked .provider-check:before {
    background: var(--orange-100);
}

.provider-block.logistics.checked .provider-check:before {
    background: var(--blue-100);
}

.provider-block.production:hover, .provider-block.production.checked {
    background: var(--orange-30);
    border: 1px solid var(--orange-60);
}

.provider-block.production:hover .provider-check, .provider-block.production.checked .provider-check {
    border: 1px solid var(--orange-60);
}

.provider-block.logistics:hover, .provider-block.logistics.checked {
    background: var(--blue-30);
    border: 1px solid var(--blue-60);
}

.provider-block.logistics:hover .provider-check, .provider-block.logistics.checked .provider-check {
    border: 1px solid var(--blue-60);
}

.provider-check {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 11px;
    border: 1px solid var(--black-15);
    top: 12px;
    right: 12px;
    background: var(--black-5);
    transition: all .3s;
}

.provider-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
}

.production .provider-content img {
    height: 30px;
}

.provider-content img {
    height: 26px;
    margin-bottom: 10px;
}

#file-add {
    width: 1px;
    height: 1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.file-block {
    height: 190px;
    width: 200px;
    margin-right: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    display: inline-block;
    margin-bottom: 20px;
}

.file-preview {
    height: 110px;
    width: 100%;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
}

.file-info {
    height: 80px;
    border-radius: 0 0 12px 12px;
    position: relative;
}

.file-info span {
    width: calc(100% - 10px);
    display: block;
    padding: 5px 10px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
}

.file-buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.file-buttons a {
    height: 30px;
    display: flex;
}

.file-button {
    width: 40px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid var(--blue-30);
    background: var(--white-100);
    margin-left: 5px;
    position: relative;
    transition: all .3s;
}

.file-button:hover {
    border: 1px solid var(--blue-100);
    background: var(--blue-100);
}

.file-button.view:hover:before {
    background: url('../icons/see-more--white.svg') no-repeat;
    background-size: contain;
}

.file-button.delete:hover:before {
    background: url('../icons/delete-full--white.svg') no-repeat;
    background-size: contain;
}

.file-button.delete:hover {
    border: 1px solid var(--red-100);
    background: var(--red-100);
}

.file-button.delete {
    border: 1px solid var(--red-30);
}

.file-button.view:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 9px;
    width: 20px;
    height: 20px;
    background: url('../icons/see-more--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.file-button.download:before {
    content: '';
    position: absolute;
    top: 6px;
    left: 12px;
    width: 16px;
    height: 16px;
    background: url('../icons/download-full--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.file-button.download:hover:before {
    background: url('../icons/download-full--white.svg') no-repeat;
    background-size: contain;
}

.file-button.delete:before {
    content: '';
    position: absolute;
    top: 6px;
    left: 11px;
    width: 16px;
    height: 16px;
    background: url('../icons/delete-full--red.svg') no-repeat;
    background-size: contain;
}

.files-container {
    margin-bottom: 20px;
    width: 100%;
}

.custom-page-file canvas {
    width: 200px !important;
    height: auto !important;
    min-height: 110px !important;
}

.file-preview img {
    width: 200px;
    height: auto;
    min-height: 110px;
}

.scrollable-page {
    overflow-y: scroll;
    height: calc(100% - 80px);
}

.scrollable-actions {
    overflow-y: scroll;
    height: calc(100% - 115px);
}

.frequency-container {
    font-weight: 500;
}

.frequency-container input, .frequency-container select {
    margin-left: 10px;
    height: 35px;
    border-radius: 10px;
    border: 1px solid var(--black-15);
    padding: 0 10px;
}

.flex-view {
    height: 100%;
    display: flex;
    justify-content: center;
}

.flex-view .left-container {
    width: 60%;
}

.flex-view .right-container {
    width: 40%;
    height: calc(100% - 80px);
    border-left: 1px solid var(--black-5);
    position: relative;
}

.order-portal .flex-view .right-container {
    height: calc(100% - 100px);
}

.action-date {
    font-size: .8em;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--black-50);
    text-align: center;
    padding: 6px 0;
}

.action-block {
    padding: 6px 0;
}

.custom-action:first-child {
    padding-top: 6px;
}

.custom-action:last-child {
    padding-bottom: 6px;
}

.action-block.message, .order-portal .action-block.provider-message {
    display: flex;
    padding-right: 25px;
    justify-content: flex-end;
}

.action-block.provider-message, .order-portal .action-block.message {
    display: flex;
    padding-left: 25px;
    justify-content: flex-start;
}

.action-block.provider-message span, .order-portal .action-block.message span {
    white-space: pre-line;
    display: block;
    width: 75%;
    background: var(--black-5);
    padding: 15px;
    border-radius: 16px;
    font-weight: 500;
    font-size: .92em;
    color: var(--black-100);
}

.action-block.message span, .order-portal .action-block.provider-message span {
    white-space: pre-line;
    display: block;
    width: 75%;
    background: var(--blue-100);
    color: white;
    padding: 15px;
    border-radius: 16px;
    font-weight: 500;
    font-size: .92em;
}

.action-block.date-event {
    display: flex;
    justify-content: center;
    font-size: .9em;
    font-weight: 600;
    color: var(--black-60);
    padding: 8px 16px;
    text-align: center;
}

.action-block span {
    position: relative;
}

.action-block.date-event span {
    padding-left: 22px;
}

.action-block.date-event span:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    width: 15px;
    height: 15px;
    background: url('../icons/calendar--gray.svg') no-repeat;
    background-size: contain;
}

.action-sender-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    height: 115px;
    width: 100%;
    border-top: 1px solid  var(--black-5);
}

.action-sender-input {
    width: 85%;
    padding: 20px 0 20px 20px;
    height: 100%;
}

.action-sender-input textarea {
    background: var(--black-5);
    border: 0;
    border-radius: 16px;
    width: 100%;
    padding: 10px 15px;
    height: 100%;
    resize: none;
}

.action-sender-button {
    width: 15%;
    padding: 30px 15px 30px 15px;
    height: 100%;
}

.sender-button {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 16px;
    position: relative;
    background: var(--white-100);
    transition: all .3s;
}

.sender-button:before {
    content: '';
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 22px;
    height: 22px;
    background: url('../icons/send--blue.svg') no-repeat;
    background-size: contain;
}

.sender-button:hover {
    background: var(--blue-5);
}

.order-portal {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.portal-header {
    height: 100px;
    border-bottom: 1px solid var(--black-5);
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.portal-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.portal-title img {
    width: 50px;
    margin-right: 20px;
}

.portal-phone {
    border: 1px solid var(--black-15);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px 0 45px;
    border-radius: 12px;
    color: var(--black-100);
    transition: all .3s;
    position: relative;
}

.portal-phone:before {
    content: '';
    position: absolute;
    left: 15px;
    top: 9px;
    width: 20px;
    height: 20px;
    background: url('../icons/phone--black.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.portal-phone:hover {
    text-decoration: none;
    color: var(--blue-100);
    background: var(--blue-5);
    border: 1px solid var(--blue-40);
}

.portal-phone:hover:before {
    background: url('../icons/phone--blue.svg') no-repeat;
    background-size: contain;
}

.order-portal-welcome {
    padding-left: 40px;
}

.order-portal-welcome:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 28px;
    height: 28px;
    background: url('../icons/hello.svg') no-repeat;
    background-size: contain;
}

.waiting-order {
    height: 60px;
    border: 1px solid var(--black-15);
    border-radius: 18px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 0 10px 0 20px;
}

.waiting-order span {
    padding-right: 14px;
    margin-right: 14px;
    border-right: 1px solid var(--black-5);
}

.waiting-orders-container p {
    margin-bottom: 10px;
}

.waiting-order .no-border, .waiting-order.disabled .no-border {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
}

.provider-name {
    padding-left: 25px;
    position: relative;
}

.provider-name:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 20px;
    height: 20px;
    background: url('../icons/order--blue.svg') no-repeat;
    background-size: contain;
}

.waiting-orders-container {
    font-weight: 500;
    margin-bottom: 30px;
}

.order-receipt-confirm {
    height: 40px;
    border: 1px solid var(--green-170-40);
    background: var(--green-30);
    border-radius: 12px;
    padding: 0 15px;
    color: var(--green-170);
    font-weight: 600;
    transition: all .3s;
}

.order-receipt-confirm:hover {
    background: var(--green-100);
    color: var(--white);
    border: 1px solid var(--green-100);
}

.waiting-order.disabled {
    width: 100%;
}

.waiting-orders-block {
    width: fit-content;
    justify-content: flex-start;
}

.waiting-orders-block .waiting-order.disabled {
    justify-content: flex-start;
    background: var(--black-5);
}

.waiting-orders-block .waiting-order.disabled span {
    color: var(--black-60);
}

.waiting-order.disabled .provider-name:before {
    background: url('../icons/order--gray.svg') no-repeat;
    background-size: contain;
}

.waiting-order.disabled span {
    border-right: 1px solid var(--black-15);
}

.waiting-order-warning {
    background: var(--blue-5);
    color: var(--blue-100);
    height: 42px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    border-radius: 12px;
    font-size: .9em;
}

.delivery-date-container {
    padding: 14px;
    border: 1px solid var(--black-15);
    border-radius: 18px;
    margin-bottom: 30px;
    font-weight: 500;
}

.delivery-date-title {
    padding-left: 35px;
    position: relative;
}

.delivery-date-title:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 22px;
    height: 22px;
    background: url('../icons/shipping--blue.svg') no-repeat;
    background-size: contain;
}

.warning-delivery {
    color: var(--red-100);
}

.delivery-container {
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.datepicker-block {
    width: 200px;
    height: 100%;
}

.datepicker-block .estimation-button {
    height: 100%;
    width: calc(100% - 5px);
    background: var(--blue-100);
    border: 0;
    border-radius: 12px 2px 2px 12px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .3s;
}

.datepicker-block .estimation-button:hover {
    background: var(--blue-120);
}

.datepicker-block .estimation-button span {
    font-weight: 600;
    font-size: 1.1em;
}

.datepicker-block .estimation-button img {
    margin-bottom: 10px;
}

.address-block {
    width: calc(100% - 200px);
    height: 100%;
    background: var(--blue-5);
    border-radius: 2px 12px 12px 2px;
    display: flex;
    flex-direction: column;
    padding: 10px 0 10px 25px;
}

.address-block span {
    margin-bottom: 2px;
}

.address-block .delivery {
    font-weight: 600;
    color: var(--blue-120);
}

.address-block .provider-title {
    font-weight: 700;
    font-size: 1.3em;
}

.address-block .provider-address {
    font-weight: 600;
}

.weekday-select .select__control {
    border-radius: 12px;
    min-width: 140px;
    height: 40px;
}

.weekday-select .select__option {
    border-radius: 12px;
    cursor: pointer;
}

.input-time {
    height: 40px;
    border-radius: 12px;
    border: 1px solid var(--black-30);
    padding: 0 10px;
}

.subsection-title {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 20px;
    display: block;
}

.map-select {
    width: 100%;
    height: 40px;
    border-radius: 12px;
    padding: 0 10px;
    border: 1px solid var(--black-30);
}

.header-title.flex-title {
    display: flex;
    align-items: center;
}

.custom-badge {
    padding: 0 10px;
    background: var(--blue-100);
    color: white;
    font-size: 1.2em;
    font-weight: 800;
    border-radius: 12px;
}

.header-title .custom-badge {
    margin-top: 2px;
}

.header-title .custom-badge.margin-left {
    margin-left: 20px;
}

.custom-badge.green-badge {
    background: var(--green-100);
}

.guest-importer .input-row-container {
    margin-bottom: 12px;
}

.text-note {
    background: var(--blue-20);
    padding: 4px 9px;
    border-radius: 8px;
    display: block;
    font-weight: 500;
}

.guest-importer .text-note {
    margin-bottom: 10px;
}

.csv-file-input {
    position: relative;
}

.csv-file-input .csv-reader-input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.csv-file-input .label-file {
    background: var(--black-5);
    border: 2px dashed var(--black-30);
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
    font-weight: 600;
    transition: all .2s;
    margin-bottom: 10px;
}

.csv-file-input .label-file:hover {
    background: var(--black-15);
}

.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.fmi-data {
    display: flex;
    align-items: center;
}

.searchbar-item .input-container {
    width: 100%;
}

.searchbar-item .input-container .select__placeholder {
    display: none;
}

.searchbar-item .input-container .select__control {
    background-color: var(--black-5);
}

.fmi-container {
    background-color: var(--black-5);
    padding: 20px;
    border-radius: 12px;
}

.fmi-block {
    margin-bottom: 16px;
}

.fmi-block:last-child {
    margin-bottom: 0;
}

.fmi-block .MuiLinearProgress-determinate {
    height: 12px;
    border-radius: 5px;
}

.fmi-block .fmi-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    margin-bottom: 5px;
}

.fmi-block .fmi-info span:first-child {
    font-size: 1.2em;
    font-weight: 600;
}

.fmi-block .MuiLinearProgress-determinate .MuiLinearProgress-bar {
    background-color: var(--blue-100);
}

.scanner-container {
    position: relative;
    height: 350px;
    overflow: hidden;
}

.scanner-container * {
    width: 100%;
    height: 100%;
}

.scanner-container #video {
    transform: translate(-50%, 0);
    object-fit: cover;
}

.scanner-aim {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    border: 4px solid #3eff008a;
}

.manual-entry-container {
    background: var(--black-5);
    padding: 20px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
}

.manual-entry-container button, .scanned-info button {
    background: var(--black-30);
    border: 0;
    height: 80px;
    width: 80px;
    border-radius: 20px;
    color: white;
    font-size: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.manual-entry-container button.entry, .scanned-info button.entry {
    background: var(--green-100);
}

.manual-entry-container button.exit, .scanned-info button.exit {
    background: var(--red-100);
}

.scanned-container .logo-scanner-container {
    padding: 10px 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scanned-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.scanned-container button {
    width: 100%;
    font-size: 1.3em;
    font-weight: 700;
}

.scanned-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scanned-info .full-width {
    margin-top: 20px;
    padding: 20px;
    border-radius: 12px;
    background: var(--black-5);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scan-history {
    padding: 20px 0;
    width: 100%;
}

.scanned-container .warning {
    background: var(--orange-30);
    padding: 20px;
    border-radius: 12px;
    font-weight: 500;
    text-align: center;
    width: 100%;
}

.history-block {
    background: var(--black-5);
    padding: 14px;
    margin-top: 10px;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.history-block:before {
    content:'';
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}

.history-block.entry:before {
    background: url("../images/entry.png") no-repeat;
    background-size: contain;
}

.history-block.exit:before {
    background: url("../images/exit.png") no-repeat;
    background-size: contain;
}

.history-block span:first-child {
    font-weight: 600;
}

.history-block span:last-child {
    font-size: .8em;
}

.scanner-actions {
    display: flex;
    width: 100%;
    margin: 20px 0 10px 0;
}

.scanner-actions button {
    width: 100%;
}

.full-width .scanner-actions {
    margin: 0;
}

.main-button.return-button {
    padding: 0;
    width: 40px;
}

.main-button.return-button:before {
    background: url('../icons/arrow-small-right.svg') no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
    top: calc(50% - 12px);
    left: calc(50% - 12px);
}

.main-button.return-button:hover:before {
    background: url('../icons/arrow-small-right--blue.svg') no-repeat;
    background-size: contain;
}

.scanner-stadium-info {
    background: var(--blue-100);
    color: white;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
}

.scanner-stadium-info .attendance {
    font-size: 1.4em;
    font-weight: 700;
}

@media (max-width: 981px) {
    .list-header {
        padding: 15px;
    }

    .header-title h1, .view-title h1, .custom-modal-header h1 {
        font-size: 1.2em;
        padding-left: 0;
    }

    .header-title h1:before {
        display: none;
    }

    .header-title .custom-badge {
        margin-left: 10px;
        font-size: 1em;
    }

    .main-button {
        font-size: 0;
        padding: 20px;
    }

    .main-button:before, .main-button.blue-button:before {
        left: 11px;
    }

    .custom-modal-container {
        width: calc(100% - 20px);
    }

    .dashboard-chart-container {
        display: none;
    }

    .fmi-container {
        margin-bottom: 30px;
    }

    .dashboard-container .MuiGrid-item {
        width: 100%;
    }
}