.main-app {
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
}

.main-app-right-part {
    width: calc(100% - 220px);
}

.main-menu {
    height: 100%;
    width: var(--menu-size);
    border-right: 1px solid var(--black-5);
}

.main-navbar, .app-logo, .mobile-navbar {
    height: 80px;
    border-bottom: 1px solid var(--black-5);
}

.main-navbar {
    display: flex;
}

.app-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-item {
    height: 75px;
    display: flex;
    align-items: center;
    padding-left: 60px;
    text-decoration: none;
    color: var(--black-100);
    font-weight: 500;
    transition: all .1s;
    position: relative;
    z-index: 0;
}

.menu-item:before {
    content: '';
    position: absolute;
    left: 21px;
    top: 28px;
    width: 22px;
    height: 22px;
    background: url('../icons/users.svg') no-repeat;
    background-size: contain;
}

.menu-item.active:before {
    background: url('../icons/users-blue.svg') no-repeat;
    background-size: contain;
}

.menu-item.trainings:before {
    background: url('../icons/swimmer.svg') no-repeat;
    background-size: contain;
}

.menu-item.stadium:before {
    background: url('../icons/stadium--black.svg') no-repeat;
    background-size: contain;
    width: 20px;
}

.menu-item.settings:before {
    background: url('../icons/settings--black.svg') no-repeat;
    background-size: contain;
}

.menu-item.scanner:before {
    background: url('../icons/scanner.svg') no-repeat;
    background-size: contain;
}

.menu-item.logout:before {
    background: url('../icons/logout--red.svg') no-repeat;
    background-size: contain;
}

.menu-item.trainings.active:before {
    background: url('../icons/swimmer-blue.svg') no-repeat;
    background-size: contain;
    top: 29px;
}

.menu-item.subscriptions:before {
    background: url('../icons/ticket.svg') no-repeat;
    background-size: contain;
}

.menu-item.subscriptions.active:before {
    background: url('../icons/ticket-blue.svg') no-repeat;
    background-size: contain;
    left: 23.5px;
}

.menu-item.pools:before {
    background: url('../icons/home-location-alt.svg') no-repeat;
    background-size: contain;
}

.menu-item.pools.active:before {
    background: url('../icons/home-location-alt-blue.svg') no-repeat;
    background-size: contain;
}

.menu-item:hover, .menu-item.active {
    background: var(--black-5);
}

.menu-link:hover {
    text-decoration: none;
}

.new-button {
    position: absolute;
    right: 18px;
    top: 17px;
    font-size: 28px;
    font-weight: 200;
    color: var(--black-70);
    transition: all .5s;
    z-index: 2;
}

.new-button:hover {
    color: var(--white-100);
}

.new-button:before {
    content: '';
    position: absolute;
    top: 9px;
    right: -5px;
    width: 26px;
    height: 26px;
    border: 1px solid var(--black-15);
    border-radius: 9px;
    z-index: -1;
    transition: all .5s;
}

.new-button:hover:before {
    background: var(--blue-100);
    border: 1px solid var(--blue-100);
}

.searchbar-container {
    width: 100%;
    padding: 20px;
}

.searchbar-item input {
    height: 40px;
    width: 100%;
    border: 1px solid var(--black-15);
    border-radius: 12px;
    background: var(--black-5);
    padding: 0 20px 0 40px;
    font-weight: 500;
    font-size: 0.9em;
}

.searchbar-item {
    position: relative;
}

.searchbar-item:before {
    content: '';
    position: absolute;
    left: 12px;
    top: 12px;
    width: 18px;
    height: 22px;
    background: url('../icons/search.svg') no-repeat;
    background-size: contain;
}

.user-info-container {
    border-left: 1px solid var(--black-5);
    padding: 20px;
}

.user-item {
    height: 40px;
    background: var(--blue-20);
    border-radius: 12px;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    font-weight: 700;
    padding: 0 50px 0 40px;
    white-space: nowrap;
    position: relative;
    min-width: 200px;
    cursor: pointer;
    transition: all .3s;
    color: var(--blue-170);
}

.user-item:hover {
    background: var(--blue-100);
    color: var(--white-100);
}

.user-item:hover:before {
    background: url('../icons/user-white.svg') no-repeat;
    background-size: contain;
}

.user-item:hover:after {
    background: url('../icons/arrow-toggle-white.svg') no-repeat;
}

.user-item:before {
    content: '';
    position: absolute;
    left: 14px;
    top: 10px;
    width: 15px;
    height: 20px;
    background: url('../icons/user-blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.user-item:after {
    content: '';
    position: absolute;
    right: 12px;
    top: 16px;
    width: 15px;
    height: 22px;
    background: url('../icons/arrow-toggle.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.user-options-item, .delete-button {
    height: 40px;
    border: 1px solid var(--blue-60);
    width: 200px;
    border-radius: 12px;
    margin-top: 6px;
    background: var(--white-100);
    padding-left: 40px;
    color: var(--blue-100);
    font-size: 0.9em;
    font-weight: 600;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: all .3s;
}

.user-options-item:hover {
    background: var(--blue-20);
    color: var(--blue-100);
    text-decoration: none;
}

.user-options-item:before {
    content: '';
    position: absolute;
    left: 12px;
    top: 10px;
    width: 17px;
    height: 22px;
    background: url('../icons/settings-blue.svg') no-repeat;
    background-size: contain;
}

.user-options-item.users:before {
    width: 18px;
    left: 11px;
    background: url('../icons/users-blue.svg') no-repeat;
    background-size: contain;
}

.user-options-item.logout, .user-options-item.delete {
    border: 1px solid var(--red-100);
    color: var(--red-100);
}

.user-options-item.logout:before {
    background: url('../icons/logout--red.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    top: 11px;
    left: 14px;
    width: 18px;
}

.user-options-item.logout:hover, .user-options-item.delete:hover {
    background: var(--red-100);
    color: var(--white-100);
}

.user-options-item.logout:hover:before {
    background: url('../icons/logout--white.svg') no-repeat;
    background-size: contain;
}

.user-options-item.link:before {
    background: url('../icons/link--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    height: 18px;
    top: 10px;
}

.user-options-item.download:before {
    background: url('../icons/download--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    top: 10px;
}

.user-options-item.delete:before {
    background: url('../icons/delete--red.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    top: 11px;
    left: 13px;
    width: 16px;
}

.user-options-item.delete:hover:before {
    background: url('../icons/delete--white.svg') no-repeat;
    background-size: contain;
}

.user-options-item.archive:before {
    background: url('../icons/archive--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    top: 12px;
    left: 12px;
    width: 19px;
}

.dashboard-container {
    display: flex;
    height: calc(100vh - 80px);
    padding: 30px;
}

.dashboard-container .left-side {
    width: 70%;
    padding-right: 30px;
    overflow-y: scroll;
}

.dashboard-container .right-side {
    width: 30%;
}

.dashboard-container .list-header, .gate-chooser-container .list-header {
    padding: 0 0 30px 0;
}

.dashboard-container .list-header.padding-top {
    padding: 30px 0;
}

.dashboard-container .main-data, .gates-container .main-data {
    background: var(--blue-100);
    padding: 14px 20px;
    color: white;
    font-weight: 500;
    border-radius: 20px;
}

.gates-container .main-data {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    transition: all .3s;
    cursor: pointer;
}

.gates-container .main-data:hover {
    background: var(--blue-120);
}

.gate-info {
    padding: 20px 14px;
    background: var(--blue-100);
    color: white;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gate-info .change-gate {
    cursor: pointer;
    padding: 2px 8px;
    background: var(--white);
    border-radius: 12px;
    color: var(--red-100);
}

.gates-container .main-data h1 {
    font-weight: 600;
    font-size: 1.5em;
    margin-bottom: 0;
}

.dashboard-container .main-data h1 {
    font-weight: 600;
    font-size: 2em;
    margin-bottom: 0;
}

.active-scanners-container {
    padding: 20px;
    border: 1px solid var(--black-15);
    height: 100%;
    border-radius: 20px;
}

.active-scanners-title {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    padding-bottom: 14px;
    border-bottom: 1px solid var(--black-15);
}

.active-scanners-title span {
    padding-left: 10px;
    font-weight: 600;
}

.active-scanners-block {
    background: var(--black-5);
    padding: 8px 14px 8px 44px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    position: relative;
}

.active-scanners-block div:first-child {
    display: flex;
    flex-direction: column;
}

.active-scanners-block:before {
    content:'';
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: grey;
}

.active-scanners-list {
    overflow-y: scroll;
    height: calc(100% - 61px);
}

.active-scanners-block.inactive:before {
    background: var(--red-170-40);
}

.active-scanners-block.active:before {
    background: var(--green-100);
}

.active-scanners-block .scanner-name {
    font-weight: 600;
}

.active-scanners-block .scanner-gate {
    font-size: .9em;
}

.active-scanners-block.inactive .scanner-gate {
    font-style: italic;
    color: var(--black-60);
}

.active-scanners-block.active .scanner-gate {
    font-weight: 500;
}

.active-scanners-block .total-scans {
    padding: 0 8px;
    background: var(--blue-100);
    color: white;
    font-weight: 700;
    border-radius: 12px;
}

.dashboard-chart-container {
    margin: 30px 0;
    height: 350px;
    padding: 20px;
    border: 1px solid var(--black-15);
    border-radius: 12px;
}

.mobile-navbar {
    display: none;
}

.burger-menu-container {
    padding: 0 20px;
}

.mobile-hidden {
    display: none;
}

.gate-chooser-container {
    padding: 30px;
}

@media (max-width: 981px) {
    .main-app {
        display: block;
        overflow-y: unset;
    }

    .main-menu {
        position: absolute;
        z-index: 9;
        background: white;
        top: 80px;
        width: 100%;
        display: none;
        transition: all .3s;
    }

    .main-menu.open {
        display: block;
        height: calc(100% - 80px);
    }

    .main-menu .app-logo {
        display: none;
    }

    .main-app-right-part {
        width: 100%;
    }

    .main-navbar {
        display: none;
    }

    .mobile-navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        width: 100%;
        background: var(--white);
        z-index: 99;
    }
    .mobile-hidden {
        display: block;
    }

    .dashboard-container {
        flex-direction: column;
        padding: 20px;
        height: 100%;
    }

    .dashboard-container .left-side, .dashboard-container .right-side {
        width: 100%;
        padding-right: 0;
    }

    .menu-item {
        height: 60px;
        border-bottom: 1px solid var(--black-5);
    }

    .menu-item:before {
        top: 20px;
    }

    .new-button {
        right: 24px;
        top: 8px;
    }

    .main-router-content, .portal-content {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .dashboard-container .main-data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 20px;
    }

    .gate-chooser-container {
        padding: 20px;
    }
}